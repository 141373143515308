
import { defineComponent, PropType } from "vue";
import {
  IonPage,
  IonContent,
  IonText,
  IonButton,
  toastController,
  IonInput,
} from "@ionic/vue";
import PageHeader from "@/components/partial/PageHeader.vue";
import { create } from "ionicons/icons";
import axios from "axios";

export default defineComponent({
  name: "Checkout",

  components: { IonPage, IonContent, IonText, IonButton, PageHeader, IonInput },

  setup() {
    return { create };
  },
  mounted() {
    this.getDetailPayment();
  },
  methods: {
    onChangeFile($event) {
      this.image = $event.target.files[0];
    },
    async getDetailPayment() {
      const param = this.$route.params.id.split(":");
      const formdata = new FormData();
      formdata.append("trx", param[0]);
      formdata.append("id_payment", param[1]);

      await axios.post("payment-list", formdata).then(({ data }) => {
        this.summary = data;
      });
    },
    async bayarSekarangNanti() {
      const id = await this.$route.params.id as any
      const split = id.split(":")
      const no_transksi = split[0]
      const id_payment = split[1]
      
      const formdata = new FormData();
      formdata.append("trx", no_transksi.toString());
      formdata.append("id_payment", id_payment.toString());
      if (id_payment > 1) {
        if (this.image) {
          formdata.append("pay_img", this.image);
        } else {
          const toast = await toastController.create({
            message: "Silakan pilih bukti pembayaran dahulu",
            duration: 1500,
          });
          return toast.present();
        }
      }
      this.loading = true;
      await axios
        .post("pay", formdata)
        .then(async ({ data }) => {
          this.loading = false;
          if (data.success) {
            const toast = await toastController.create({
              message: "Berhasil Memesan Pesanan Anda! Silakan cek di menu Proses untuk detail pesanan",
              duration: 2500,
            });
            toast.present();
            return this.$router.replace(`/main/order`);
          }
        })
        .catch(({ response }) => {
          this.loading = false;
        });
    },
  },
  data() {
    return {
      image: null,
      user: {
        name: "Fizzy Wizzy",
        phone: "08123456789",
        address: "Bengkong Harapan 1 Blok F No.48",
      },
      loading: false,
      summary: {} as any,
      menu: {
        name: "Sop Tulang Kuah Rawon",
        day: "Rabu",
        date: "1 September 2021",
        portion: 10,
        img: "https://lorempixel.com/128/80/food",
      },
    };
  },
});
